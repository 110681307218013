import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import { isAuthenticated } from '@/helpers';

Vue.use(VueRouter)
Vue.use(VueBreadcrumbs);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: () => import("@/views/user/SignIn.vue")
    },
    {
        path: '/sign-in',
        name: 'sign in',
        component: () => import("@/views/user/SignIn.vue")
    },
    {
        path: '/admin',
        component: () => import("@/components/ui/AdminPanel.vue"),
        meta: {
            breadcrumb: 'Admin'
        },
        children: [
            {
                path: '/admin/api-keys',
                name: 'api keys',
                component: () => import("@/views/user/ManageApiKey.vue"),
            },
            {
                path: '/',
                name: 'dashboard',
                component: () => import("@/views/admin/dashboard/AdminDashboard.vue")
            },
            {
                path: '/admin/restaurants/add',
                name: 'add restaurant',
                component: () => import("@/views/admin/restaurant/AddRestaurant.vue"),
                meta: {
                    breadcrumb: 'Create Restaurant'
                }
            },
            {
                path: '/admin/restaurants/add/:id',
                name: 'update restaurant',
                component: () => import("@/views/admin/restaurant/AddRestaurant.vue"),
                meta: {
                    breadcrumb: 'Update Restaurant'
                }
            },
            {
                path: '/admin/restaurants/:id/menus',
                name: 'manage restaurant menus',
                component: () => import("@/views/admin/menu/MenuList.vue"),
                meta: {
                    breadcrumb: 'Menus'
                }
            },
            {
                path: '/admin/restaurants',
                name: 'restaurants',
                component: () => import("@/views/admin/restaurant/ListRestaurant.vue"),
                meta: {
                    breadcrumb: 'Restaurants'
                },
            },
            {
                path: '/admin/restaurants/:id/tables',
                name: 'manage restaurant tables',
                component: () => import("@/views/admin/table/ListTables.vue"),
                meta: {
                    breadcrumb: 'Tables'
                }
            },
            {
                path: '/admin/menu/:id',
                name: 'manage restaurant menu',
                component: () => import("@/views/admin/menu/ManageMenu.vue")
            },
            {
                path: '/admin/menu/:mid/menu-item/:id',
                name: 'manage menu item',
                component: () => import("@/views/admin/menu-item/ManageMenuItem.vue")
            }
        ]
    },
    {
        path: '/sign-up',
        name: 'sign up',
        component: () => import("@/views/user/SignUp.vue")
    },
    {
        path: '/menu/:id/menu-item/add',
        name: 'add menu item',
        component: () => import("@/components/menu/builder/menu-item/AddMenuItemForm.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// add an authentication guard

// router.beforeEach((to, from, next) => {
//     const authenticated = isAuthenticated()
//     if (to.name !== 'sign in' && !authenticated) next({ name: 'sign in' })
//     next()
// })

export default router
