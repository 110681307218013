import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'


Vue.config.productionTip = false

Vue.use(Toast, {
  position: POSITION.TOP_RIGHT,
  icon: true,
  timeout: 5000 // 5 seconds
})

Vue.config.productionTip = true

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
