<template>
  <div id="app">
    <app-navbar />
    <div class="container-fluid">
      <router-view />
    </div>
    <app-footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  components: {
    AppFooter: () => import("@/components/ui/Footer.vue"),
    AppNavbar: () => import("@/components/ui/Navbar.vue"),
  },
  name: 'App',
});
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.form-control-lg {
  font-size: 1.45rem !important;
}

/* font size classes */

.font-size-1 {
  font-size: 1.1rem;
}

.font-size-2 {
  font-size: 2rem;
}

/* text decorations */

.text-decoration-underline {
  text-decoration: underline;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

a,
a:hover {
  color: #2c3e50;
  text-decoration: none;
}
</style>
